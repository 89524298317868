<template>
  <footer class="footer_section bg-black">
    <div class="container_fluid container row-col pt-[4.375rem] pb-24 md:pb-12 flex flex-col gap-[2.5rem]">
      <div
        class="displace_ grid md:grid-cols-2 items-start md:gap-x-10 justify-between"
      >
        <div
          class="left_side max-w-[45rem] pb-12 lg:pb-0 flex flex-col h-full"
        >
          <div class="about_us flex flex-col gap-[0.875rem]">
            <a class="logo_container w-[6.875rem] inline-block">
              <img
                alt="logo"
                class="src"
                height="59.86"
                src="~/assets/images/logo.svg"
                width="110"
              />
            </a>
            <p
              class="font-body text-[#A0A0A2] leading-[1.5rem] md:leading-[1.3125rem] text-[0.9375rem] md:text-[0.875rem] font-normal"
            >
              The most trustworthy cryptocurrency exchange platform
              <br class="hidden md:block" />
              available. Where you can have quick access to your
              <br class="hidden md:block" />
              money whenever you desire.
            </p>
          </div>

          <div class="flex items-start justify-start mt-8 md:mt-auto relative">
            <img
              src="~/assets/images/NDPR.webp"
              alt="NDPR Compliance Certificate - Audit Reg No: NDPC/AUDIT/12844"
              class="object-contain w-36 h-auto max-h-[8.125rem] relative z-10"
            />
            <a 
              href="https://services.ndpc.gov.ng/portal/?page=verify-c&d=ang359254545361&id=65781&sn=0fbcdd970d77d33ae3963965c5f04dae&t=audit_filing&tp=nwp_audit"
              target="_blank"
              rel="noopener noreferrer"
              class="absolute inset-0 z-20"
            ></a>
          </div>
        </div>
        <div class="flex flex-col mb-12 md:mb-0 h-full">
          <div
            class="footers_links_container flex flex-wrap justify-between w-full mb-16 md:mb-24"
          >
            <div
              v-for="(footer, index) in footer_links"
              :key="index"
              class="feature_box flex flex-col gap-4 w-1/2 md:w-auto mb-8 md:mb-0"
            >
              <p class="title text-white text-base font-medium">
                {{ footer.title }}
              </p>
              <ul class="flex flex-col space-y-2">
                <li
                  v-for="(link, index) in footer.links"
                  :key="index"
                  class="link font-normal font-body text-[#F2F2F2] hover:text-primary-1 text-sm whitespace-nowrap"
                >
                  <a
                    v-if="footer.title == 'Features'"
                    :href="link.link + (link.name || '')"
                    >{{ link.label }}</a
                  >
                  <NuxtLink v-else :href="link.link">{{ link.label }}</NuxtLink>
                </li>
              </ul>
            </div>
          </div>
          <div class="app_store_actions w-full md:w-[18.75rem] self-end mt-auto">
            <p class="title text-base font-medium text-gray-3">
              Download the app now
            </p>
            <StoreButtonsContainer :md-hidden="false" class="w-full" />
          </div>
        </div>
      </div>
      <div
        class="copyright px-4 bg-black pt-8 container border-t border-gray-1 text-center text-content-4"
      >
        <div
          class="content row-col items-center gap-[1.5rem] md:flex-row-reverse justify-between"
        >
          <div class="socials flex items-center gap-6">
            <a
              v-for="(social, index) in socials"
              :key="index"
              :href="social.link"
              class="inline-flex items-center hover:text-primary-1 text-white justify-center w-[2.5rem] h-[2.5rem] rounded-[50%]"
            >
              <component :is="social.icon" :alt="social.label" />
            </a>
          </div>
          <p class="font-body text-base text-[#A0A0A2]">
            Copyright © {{ currentYear }} Apex. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts" setup>
// import {returnWebsiteUrl} from "~/utils";
import IconFacebook from "~/components/icons/IconFaceBook.vue";
import IconInstagram from "~/components/icons/IconInstagram.vue";
import IconTwitter from "~/components/icons/IconTwitter.vue";
import IconLinkedin from "~/components/icons/IconLinkedin.vue";
import { computed } from "#imports";
import { useRoute } from "#app";
// import { Title } from ".nuxt/components";
const route = useRoute();
const currentYear = computed(() => new Date().getFullYear());

const socials = [
  {
    label: "Twitter",
    link: "https://twitter.com/useapexnetwork",
    icon: IconTwitter,
  },
  {
    label: "LinkedIn",
    link: "https://www.linkedin.com/company/apex-network-limited/mycompany/",
    icon: IconLinkedin,
  },
  {
    label: "Facebook",
    link: "https://web.facebook.com/useapexnetwork",
    icon: IconFacebook,
  },
  {
    label: "Instagram",
    link: "https://www.instagram.com/useapexnetwork/",
    icon: IconInstagram,
  },
];

type FooterLink = {
  label: string;
  link: string;
  name?: string; // Optional since it's only used in Features section
};

type FooterSection = {
  title: string;
  links: FooterLink[];
};

type FooterLinks = FooterSection[];

const footer_links: FooterLinks = [
  {
    title: "Company",
    links: [
      {
        label: "Contact Us",
        link: "/contact-us",
      },
      {
        label: "About Us",
        link: "/about-us",
      },
      {
        label: "Our Careers",
        link: "https://apply.workable.com/apex-network-1/",
      },
    ],
  },
  {
    title: "Features",
    links: [
      {
        label: "Swap Cryptocurrency",
        link: "/services",
        name: "#swap",
      },
      {
        label: "Exchange Gift cards",
        link: "/services",
        name: "#gift-cards",
      },
      {
        label: "Bill Payments",
        link: "/services",
        name: "#payments",
      },
    ],
  },
  {
    title: "Legal",
    links: [
      {
        label: "Privacy Notice",
        link: "/privacy-notice",
      },
      {
        label: "Terms of Use",
        link: "/terms-and-conditions",
      },
    ],
  },
  {
    title: "Others",
    links: [
      {
        label: "Business",
        link: "/business",
      },
      {
        label: "Marketing",
        link: "#",
      },
      {
        label: "Blog",
        link: "https://blog.apexnetwork.co/",
      },
    ],
  },
];
</script>

<style lang="scss" scoped>
.store_btn_container {
  @apply lg:flex-row;
}

.copyright a img {
  @apply w-6 h-6;
}
</style>
